.loginBox {
  .loginContainer {
    .form-control {
      border: none;
      border-bottom: 1px solid #9d9595;
      border-radius: 0;
    }
    .me-2 {
      width: 20%;
    }
    .logo-header {
      background: #fff;
      border-bottom: 1px solid #ece6e6;
      padding: 1em !important;
    }
    .remeberme {
      text-align: left;
    }

    .rememberLabel {
      padding-left: 0.2rem;
      vertical-align: middle;
      margin: 0px;
      font-size: 12px;
    }
    .padding-left-0 {
      padding-left: 0;
    }
  }
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 70%;
  z-index: -999999;
}
.bg-bubbles li {
  position: absolute;
  z-index: -999999;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(42, 108, 130, 0.1);
  bottom: -160px;
  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
}
.bg-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}
@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}
@keyframes square {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}

.accept-invite-btn {
  width: 150px !important;
  .spinner-border {
    --bs-spinner-width: 1rem !important;
    --bs-spinner-height: 1rem !important;
  }
}

.download-loader {
  position: fixed;
  top: 40%;
  left: 45%;
  font-size: larger;
}

.download-loader-msg {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
