.fallback-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
}
.loading {
  position: absolute;
  left: calc(45% - 35px);
  top: 40%;
  width: 145px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  text-align: center;
  .logo-spinner {
    margin-bottom: 5px;
  }
}
.cd-accordion-menu {
  .loading {
    left: 12%;
  }
}
