.error-title {
  font-size: 210px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #263238;
  line-height: 210px;
}

@media (max-width: 1024px) {
  .error-title {
    font-size: 80px;
    line-height: 80px;
  }
}
